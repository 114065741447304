import React from 'react';
import map from 'lodash/map';
import Image from 'next/image';
import Logo from '~/logo/logo_full.png';
import Button from '@/components/button';
import Line from '@/components/line';
interface LinkProps {
  title: string;
  href: string;
}

const links: LinkProps[] = [
  {
    title: 'Website T&C',
    href: '/assets/documents/terms-conditions.pdf'
  },
  {
    title: 'Privacy Policy',
    href: '/assets/documents/privacy-policy.pdf'
  },
  {
    title: 'Cookie policy',
    href: '/assets/documents/cookie-policy.pdf'
  }
];
const SocialLink: React.FC<{ link: string; icon: string; title: string }> = ({
  title,
  icon,
  link
}) => {
  return (
    <a
      href={link}
      target="_blank"
      className="rounded-[50%] p-3.5 [background:#0a06165a]"
      rel="noreferrer"
    >
      <img src={icon} alt={title} />
    </a>
  );
};
const Footer: React.FC = () => {
  return (
    <div className="mt-20 lg:mt-40" id="link_footer">
      <Line variant="medium" className="md:z-10" />
      <section className="relative overflow-hidden bg-gradient-to-t from-blue to-purple py-12 shadow-purple lg:pb-40 lg:pt-32">
        <picture className="absolute inset-x-0 bottom-0">
          <img
            src="/assets/background/bg_footer.png"
            alt="dark"
            className="h-auto w-full opacity-80"
          />
        </picture>
        <div className="container">
          <div className="relative z-10 mx-auto w-full max-w-[1467px] overflow-hidden rounded-[36px] border border-sky/50 bg-[#150649] p-4 lg:rounded-[40px] lg:p-16">
            <picture className="absolute bottom-0 right-0 -z-20">
              <img
                src="/assets/background/bg_footer_2_op.png"
                alt="dark"
                className="h-auto w-full opacity-30"
              />
            </picture>
            <div className="md:flex md:items-center md:justify-between">
              <Image
                src={Logo}
                alt="Nestflo"
                className="w-[146px] lg:w-[200px]"
              />
              <ul className="mt-6 justify-center space-y-4 pl-3 md:mt-0 lg:flex lg:space-x-12 lg:space-y-0 lg:pl-0">
                {map(links, (link, index) => (
                  <li key={index}>
                    <a
                      href={link.href}
                      target="_blank"
                      className="hover:text-gray-300 text-sm font-medium uppercase tracking-[0.25em] text-white lg:text-base"
                      rel="noreferrer"
                    >
                      {link.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-16">
              <div className="mt-4 space-y-8 lg:w-1/2">
                <p className="font-light text-[#6523EF]">
                  Email: hello@nestflo.com <br />
                  Tel: 03301338626
                </p>
              </div>
              <div className="mt-12 flex flex-wrap justify-between space-y-8">
                <div className="lg:w-1/2">
                  <p className="font-light text-[#6523EF]">
                    Copyright 2024 Kunpro Ltd. All Rights Reserved
                  </p>
                  <p className="text-xs font-light text-[#6523EF]">
                    Kunpro ltd including trade under the brand Nestflo is
                    registered in England and Wales under registration number
                    13908049. The registered address is Unit 111, Filwood Green
                    Business Park, 1 Filwood Park Lane, Bristol, England, BS4
                    1ET, United Kingdom
                  </p>
                </div>
                <div className="flex w-full items-end justify-start gap-5 lg:w-1/2 lg:justify-end">
                  <SocialLink
                    link="https://www.linkedin.com/company/nestfloai"
                    icon="/assets/icons/linkedin.svg"
                    title="LinkedIn"
                  />
                  <SocialLink
                    link="https://www.youtube.com/@NestfloAI"
                    icon="/assets/icons/youtube.svg"
                    title="Youtobe"
                  />
                  <SocialLink
                    link="https://www.facebook.com/NestfloAI/"
                    icon="/assets/icons/facebook.svg"
                    title="Facebook"
                  />
                  <SocialLink
                    link="https://x.com/NestfloAI"
                    icon="/assets/icons/spaceX.png"
                    title="X"
                  />
                  {/*<SocialLink*/}
                  {/*  link="#"*/}
                  {/*  icon="/assets/icons/instagram.svg"*/}
                  {/*  title="Instagram"*/}
                  {/*/>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
